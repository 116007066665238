/**
 * delete 列表删除功能
 * 为了【列表删除】功能而做的公用函数
 * @auhtor suyonggang
 */
export default {
    data() {
        return {
            deleteAPI_Id: [],//接受4个参数 0:api 1:删除的id名,2:入参,3表格
            id_list: [],
            btnLoading: false,
        }
    },
    methods: {
        selectionTable(val) {
            this.id_list = val.map(item => {
                return item[this.deleteAPI_Id[2]];
            });
            console.log(this.id_list)
        },

        async onDelete(event) {
            console.log(event)
            let self = this;
            let idList = this.id_list
            let text = event.target.textContent || "操作";
            if (idList.length === 0) {
                self.$message.warning("请选中要" + text + "的项！");
                return;
            } else {
                self.$msgbox
                    .confirm("确定要" + text + "选中的项吗？", "提示", {
                        type: "warning"
                    })
                    .then(async function () {
                        self.btnLoading = true;
                        try {
                            let arr = self.deleteAPI_Id[1]
                            await self.deleteAPI_Id[0]({
                                [arr]: idList
                            });
                            self.$notify({
                                title: "成功",
                                message: text + "成功",
                                type: "success"
                            });
                            self.$search(self[self.deleteAPI_Id[3]]);

                        } catch (e) {
                            // console.error(e);
                        }
                    }).catch((e) => { });
                self.btnLoading = false;
                self.id_list = []
            }
        },
    }
}