<template>
  <div class="font">
    <ayl-berad-nav :nav="nav" />
    <div class="content-box mt20px">
      <div class="content-main mt0px">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="作业规则" name="first">
            <ayl-table-with-query-object :table="table1" @selection-change="selectionTable">
              <div slot="ctrl-button">
                <el-button class="btn" style="margin-left: 20px" @click="onAdd">+ 新增</el-button>
                <el-button
                  class="ml40px"
                  type="info"
                  style="float: right;"
                  @click="onDelete"
                  plain
                >删除</el-button>
              </div>
            </ayl-table-with-query-object>
          </el-tab-pane>
          <el-tab-pane label="标段规则管理" name="second">
            <ayl-table-with-query-object :table="personnelTable"></ayl-table-with-query-object>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    {{isShowDialog}}
    <ayl-dialog
      :visible.sync="isShowDialog"
      :viewUrl="viewUrlForDialog"
      v-model="dataForDialog"
      :async="true"
      :showBottomBtn="false"
    ></ayl-dialog>
  </div>
</template>

<script>
import list from "@/mixins/list";
import Delete from "@/mixins/delete";
export default {
  mixins: [list, Delete],
  data() {
    // eslint-disable-next-line no-unused-vars
    const vm = this;
    return {
      nav: [{ name: "作业规则管理" }],

      debug: 1,
      //弹窗有关
      viewUrlForDialog: ``,
      isShowDialog: false,
      dataForDialog: null,

      deleteAPI_Id: [
        this.$api_hw.operationSafetyManagement_regulationInfoDelete,
        "regulationIdList",
        "regulationId",
        "table1"
      ],

      common_getBidNameAndIdList: [true, 0, null], //标段管理下拉
      districtInfo_getDistinctCareUnit: [true, 1, null], // 养护企业信息下拉
      activeName: "first",
      table1: {
        api: vm.$api_hw.operationSafetyManagement_listRegulationInfoByCondition,
        query: {
          queryVO: {
            regulationType: null, //规则类别
            regulationName: null //规则名称
          }
        },
        // 是否显示分页
        // hidePagination: true,
        // 表格查询的条件组件
        searchData: [
          {
            type: "select",
            title: "规则类别",
            model: "queryVO.regulationType",
            placeholder: "请选择",
            option: vm.$enums_hw.rulesTypeOption.list
          },
          {
            type: "input",
            model: "queryVO.regulationName",
            placeholder: "规则名称"
          }
        ],
        columns: [
          {
            type: "selection",
            width: "50px"
          },
          {
            title: "序号",
            width: "50px",
            $index: "index"
          },
          {
            title: "规则名称",
            key: "regulationName"
            // width: "120px"
          },
          {
            title: "规则类别",
            key: "regulationTypeDes"
          },
          {
            title: "报警等级",
            key: "alarmLevelDes"
          },
          {
            title: "创建人",
            // width: "100px",
            key: "creator"
          },
          {
            title: "更新时间",
            key: "gmtModified",
            filter: "str2ymdhm"
            // width: "170px"
          },
          {
            title: "操作",
            align: "center",
            render(h, ctx) {
              return h("span", [
                h(
                  "span",
                  {
                    class: {
                      "table-view-hw": true
                    },
                    on: {
                      click: vm.onDetails.bind(this, ctx.row)
                    }
                  },
                  "编辑"
                )
              ]);
            }
          }
        ]
      },

      //标段规则管理
      personnelTable: {
        api:
          vm.$api_hw
            .operationSafetyManagement_listRegulationAllocationByCondition,
        query: {
          queryVO: {
            bidId: null, //标段编号
            bidMaintainUnit: null //养护企业
          }
        },
        // 是否显示分页
        // hidePagination: true,
        // 表格查询的条件组件
        searchData: [
          {
            type: "cascader_radio",
            title: "标段名称",
            model: "queryVO.bidId",
            placeholder: "请选择",
            option: []
          },
          {
            type: "select",
            title: "养护企业",
            model: "queryVO.bidMaintainUnit",
            placeholder: "请选择",
            option: []
          }
        ],
        columns: [
          {
            type: "selection",
            width: "50px"
          },
          {
            title: "序号",
            width: "50px",
            $index: "index"
          },
          {
            title: "标段名称",
            key: "bidName"
            // width: "120px"
          },
          {
            title: "养护企业",
            showTooltip: true,
            key: "bidMaintainUnit"
          },
          {
            title: "监管单位",
            showTooltip: true,
            key: "bidSuperviseUnit"
          },
          {
            title: "应用规则",
            // width: "100px",
            key: "regulationCount"
          },
          {
            title: "创建人",
            width: "140px",
            key: "creator"
          },
          {
            title: "更新时间",
            key: "gmtModified",
            filter: "str2ymd",
            align: "center",
            width: "170px"
          },
          {
            title: "启用状态",
            width: "155px",
            render(h, ctx) {
              // let text =
              //   ctx.row.disabled === 0
              //     ? { activeColor: "#13ce66" }
              //     : { inactiveColor: "#ff4949" };

              if (ctx.row.regulationCount > 0) {
                return h("div", [
                  h("el-switch", {
                    props: {
                      value: ctx.row.disabled === 0 ? true : false,
                      inactiveColor: "#ff4949",
                      activeColor: "#13ce66"
                    },
                    on: { change: vm.isOpen.bind(this, ctx.row) }
                  })
                ]);
              } else {
                return h("div", "未分配");
              }
            }
          },
          {
            title: "操作",
            width: "155px",
            render(h, ctx) {
              return h("span", [
                h(
                  "span",
                  {
                    class: {
                      "table-view-hw": true
                    },
                    on: {
                      click: vm.onDistribution.bind(this, ctx.row)
                    }
                  },
                  "分配规则"
                )
              ]);
            }
          }
        ]
      }
    };
  },

  methods: {
    handleClick(tab, event) {
      this.log(tab,"------------")
    },

    //新增
    onAdd() {
      //传views的url
      this.viewUrlForDialog =
        "/operation-safety-management/operating-rules/add";
      //传参给弹窗
      this.dataForDialog = {};
      this.isShowDialog = true; //显示弹窗
    },

    //分配规则
    onDistribution(val) {
      this.$router.push({
        path: "/operation-safety-management/operating-rules/allocation-rules",
        query: {
          id: val.bidId,
          bidName: val.bidName,
          bidSuperviseUnit: val.bidSuperviseUnit,
          bidMaintainUnit: val.bidMaintainUnit
        }
      });
    },

    //编辑
    onDetails(val) {
      console.log(val.regulationId);
      //传views的url
      this.viewUrlForDialog =
        "/operation-safety-management/operating-rules/edit";
      //传参给弹窗
      this.dataForDialog = {
        id: val.regulationId
      };
      this.isShowDialog = true; //显示弹窗
    },
    // 开启/停用
    async isOpen(val) {
      let params = {
        bidId: val.bidId,
        disabled: null
      };
      params.disabled = val.disabled === 0 ? 1 : 0;
      await this.$api_hw
        .operationSafetyManagement_regulationInfoUpdateForDisabled(params)
        .then(res => {
          this.$search(this.personnelTable);
        });
    }
  },

  async activated() {
    BUS.$on(BUSEVENT.REFRESH_OPERATING_RULES, () => {
      this.$search(this.table1);
      this.$search(this.personnelTable);
    });
  }
};
</script>
<style lang='sass' scoped>
.font
  font-family: MicrosoftYaHei,
.btn
  width: 64px
  height: 28px
  border: 1px solid rgba(26,188,156,1)
  border-radius: 4px
  color: #1ABC9C

/deep/ .table-view-edit
  padding-right: 10px
  color: #99c0ff

/deep/ .el-form--inline .el-form-item
  margin-right: 40px
/deep/ .el-table th > .cell
  color: rgba(41, 41, 41, 1)
  font-size: 14px

/deep/ .el-table .cell
  color: rgba(102, 102, 102, 1)
  font-size: 14px

.pagination
  width: 100%
  float: right
/deep/ .content-box .content-main
  padding: 24px 24px 74px
.content-box /deep/ .el-form-item
  width: auto
/deep/ .el-button--primary 
  color: #FFFFFF
  background-color: $-color-primary-3
  border-color: $-color-primary-3
/deep/ .el-checkbox__input.is-checked .el-checkbox__inner
  background-color: $-color-primary-3
  border-color: $-color-primary-3
/deep/ .el-checkbox__input.is-checked + .el-checkbox__label 
  color: $-color-primary-3
/deep/ .el-checkbox__input.is-indeterminate .el-checkbox__inner
  background-color: $-color-primary-3
  border-color: $-color-primary-3
/deep/.el-tabs__item.is-active
  color: $-color-primary-3
/deep/.el-tabs__active-bar
  background-color: $-color-primary-3
/deep/.el-tabs__item:hover
  color: $-color-primary-3
  cursor: pointer
.detail-main
  margin: 0px 30px
  font-size: 14px
  font-family: Microsoft YaHei
  font-weight: 400
  color: rgba(102,102,102,1)
  line-height: 46px

/deep/ .el-dialog__wrapper
  background: #00000054
/deep/ .v-modal
    background: none
</style>

